import type { AsyncDataRequestStatus, NuxtApp, UseFetchOptions } from "nuxt/app"

const getCachedData = (key: string, nuxtApp: NuxtApp) => {
  const data = nuxtApp.payload.data[key] ?? nuxtApp.static.data[key]
  const { data: fetchedAt } = useNuxtData(`${key}-fetchedAt`)
  if (!data || !fetchedAt.value) {
    fetchedAt.value = new Date()
    return
  }
  const expireMinutes = 30
  const expireTime = expireMinutes * 60 * 1000
  const expirationDate = new Date(fetchedAt.value)
  expirationDate.setTime(expirationDate.getTime() + expireTime)
  const isExpired = expirationDate.getTime() < Date.now()
  if (isExpired) {
    fetchedAt.value = new Date()
    return
  }
  return data
}

export function useCachedFetch<T>(
  url: string | (() => string),
  options?: Omit<UseFetchOptions<T>, "default"> & {
    default?: () => T | Ref<T | undefined>
  },
) {
  const { devState } = useDeveloperTools()
  const optionsWithCache = options ?? {}
  optionsWithCache.key = optionsWithCache.key ?? unref(url).toString()
  // Automatically set the locale if it's post request
  optionsWithCache.getCachedData = getCachedData
  if (devState.value.loading) {
    return {
      status: ref<AsyncDataRequestStatus>("pending"),
      data: ref(undefined),
      error: ref(undefined),
      refresh: () => Promise,
      execute: () => Promise,
      clear: () => Promise,
    } as const
  }
  return useFetch(url, {
    ...optionsWithCache,
    $fetch: useNuxtApp().$api,
  })
}
